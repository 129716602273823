import "reflect-metadata";

import { DI_TYPES } from "@/lib/di/types";
import type { ILogger } from "@/lib/logger";
import { type IContentfulRepository } from "@/repositories/ContentfulRepository";
import type { IDotcomRepository } from "@/repositories/DotcomRepository";
import {
	TypeConditionWithoutUnresolvableLinksResponse,
	TypeDepartmentAndProgramWithoutUnresolvableLinksResponse,
	TypeHealthAndWellnessWithoutUnresolvableLinksResponse,
	TypeRelatedEntriesWithoutUnresolvableLinksResponse,
	TypeTreatmentWithoutUnresolvableLinksResponse,
} from "@/types/contentful";
import { DotcomLocation } from "@/types/dotcom";
import { pick } from "@/utils/objects";
import { inject, injectable } from "inversify";

@injectable()
export class SpecialtiesServicesService {
	constructor(
		@inject(DI_TYPES.Logger) private _logger: ILogger,
		@inject(DI_TYPES.ContentfulRepository)
		private _contentfulRepo: IContentfulRepository,
		@inject(DI_TYPES.DotcomRepository)
		private _dotcomRepo: IDotcomRepository,
	) {}

	async fetchDepartmentProgramEntry(
		urlSlug: string,
	): Promise<TypeDepartmentAndProgramWithoutUnresolvableLinksResponse | null> {
		try {
			// Fetch entry
			const entry =
				await this._contentfulRepo.fetchDepartmentProgramByUrl(
					`/specialties-services/${urlSlug}`,
				);

			// If valid entry is found, process result into usable format
			if (entry) {
				const relatedConditions = (
					(entry.fields
						.relatedConditions as TypeConditionWithoutUnresolvableLinksResponse[]) ||
					[]
				)
					.filter(Boolean)
					.map((condition) => ({
						...condition,
						fields: pick(condition?.fields, [
							"shortTitle",
							"teaser",
							"url",
							"alternateName",
							"conditionName",
						]) as TypeConditionWithoutUnresolvableLinksResponse["fields"],
					}));
				const relatedPatientStories = (
					(entry.fields
						.relatedPatientStories as TypeHealthAndWellnessWithoutUnresolvableLinksResponse[]) ||
					[]
				)
					.filter(Boolean)
					.map((healthWellness) => ({
						...healthWellness,
						fields: pick(healthWellness.fields, [
							"summary",
							"subtitle",
							"title",
							"type",
							"url",
							"thumbnailImage",
						]),
					}));
				const relatedPrograms = (
					(entry.fields
						.relatedPrograms as TypeDepartmentAndProgramWithoutUnresolvableLinksResponse[]) ||
					[]
				)
					.filter(Boolean)
					.map(
						(dp) =>
							({
								...dp,
								fields: pick(dp.fields, [
									"brandedName",
									"departmentprogramName",
									"description",
									"officialName",
									"practiceName",
									"slug",
									"subType",
									"teaserDescription",
								]),
							}) as TypeDepartmentAndProgramWithoutUnresolvableLinksResponse,
					);
				const relatedProviders = (
					(entry.fields
						.relatedProviders as TypeRelatedEntriesWithoutUnresolvableLinksResponse[]) ||
					[]
				)
					.filter(Boolean)
					.map((provider) => ({
						...provider,
						fields: pick(provider.fields, [
							"title",
							"description",
							"relatedContent",
						]),
					}));
				const relatedTreatments = (
					(entry.fields
						.relatedTreatments as TypeTreatmentWithoutUnresolvableLinksResponse[]) ||
					[]
				)
					.filter(Boolean)
					.map(
						(treatment) =>
							({
								...treatment,
								fields: pick(treatment.fields, [
									"alternateName",
									"shortTitle",
									"url",
									"treatmentName",
								]),
							}) as TypeTreatmentWithoutUnresolvableLinksResponse,
					);

				return {
					...entry,
					fields: {
						...entry.fields,
						relatedConditions,
						relatedPatientStories,
						relatedPrograms,
						relatedProviders,
						relatedTreatments,
					},
				};
			}

			return null;
		} catch (error) {
			this._logger.error(
				`Issue occurred querying & processing Department & Program record from contentful"`,
				{
					slug: urlSlug,
					error: (error as Error)?.message || error,
				},
			);
			return null;
		}
	}

	async fetchRelatedDepartmentProgramLocations(
		urlSlug: string,
	): Promise<DotcomLocation[]> {
		try {
			const locations = await this._dotcomRepo.fetchLocations(
				`/specialties-services/${urlSlug}`,
			);

			if (locations?.length) {
				return locations;
			}

			return [];
		} catch (err) {
			this._logger.error(
				`Issue occurred fetching related locations for specialties-services url ${urlSlug}`,
				err,
			);
			return [];
		}
	}
}
