import { BuilderPageContent, BuilderPageModel } from "@/types/builder";
import { IBuilderService } from "./types";

import "reflect-metadata";

import { DI_TYPES } from "@/lib/di/types";
import type { ILogger } from "@/lib/logger";
import { inject, injectable } from "inversify";
import type { IBuilderRepository } from "@/repositories/BuilderRepository";

/**
 * BuilderService provides high-level methods to interact with Builder.io CMS
 *
 * This service class is responsible for fetching content from Builder.io using the BuilderRepository.
 * It handles logging and error management.
 */
@injectable()
export class BuilderService implements IBuilderService {
    constructor(
		@inject(DI_TYPES.Logger) private _logger: ILogger,
        @inject(DI_TYPES.BuilderRepository) private _builderRepo: IBuilderRepository,
	) {}

    async getContent(
        builderPageUrl: string,
        pageModel: BuilderPageModel = BuilderPageModel.BasePage,
    ): Promise<BuilderPageContent | null> {
        const urlPath = builderPageUrl.startsWith("/")
            ? builderPageUrl
            : `/${builderPageUrl}`;
        try {
            const content = await this._builderRepo.getPageContent(urlPath, pageModel);
            return content;
        } catch (error) {
            this._logger.error(
                `Error fetching Builder Page for url: ${urlPath}`,
                error,
            );
            return null;
        }
    }
}
