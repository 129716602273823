import "reflect-metadata";

import { DI_TYPES } from "@/lib/di/types";
import type { ILogger } from "@/lib/logger";
import { DotcomRepository } from "@/repositories/DotcomRepository";
import { inject, injectable } from "inversify";

@injectable()
export class DotcomService {
	constructor(
		@inject(DI_TYPES.Logger) private _logger: ILogger,
		@inject(DI_TYPES.DotcomRepository)
		private _dotcomRepo: DotcomRepository,
	) {}

	async subscribeToNewsletter(
		email: string,
        targetNewsletter: string,
	): Promise<void> {
		try {
            await this._dotcomRepo.callMethod({
                methodName: "newsSub",
                sourceCode: "newsletter",
                utm_medium: "referral",
                utm_source: "https://statics.teams.cdn.office.net/",
                newsletters: targetNewsletter,
                email_address: email,
            })
		} catch (err) {
			this._logger.error(
				`Failed to subscribe to newsletter "${targetNewsletter}"`,
				err,
			);
		}
	}
}
