import { env } from "@/env";
import { BuilderPageModel, BuilderPageContent } from "@/types/builder";
import { builder } from "@builder.io/sdk";
import { IBuilderRepository } from "./IBuilderRepository";
import 'reflect-metadata';
import { injectable } from "inversify";

builder.init(env.NEXT_PUBLIC_BUILDER_API_KEY);

/**
 * BuilderRepository provides an interface to interact with Builder.io CMS
 * 
 * This class handles content retrieval operations from Builder.io using their SDK.
 * It requires a valid Builder.io API key to be set in the environment variables.
 * 
 * @example
 * ```typescript
 * const repository = new BuilderRepository();
 * const content = await repository.getPageContent('/home');
 * ```
 */
@injectable()
export class BuilderRepository implements IBuilderRepository {
  /**
   * Retrieves content from Builder.io for a specific URL path and page model
   * 
   * @param urlPath - The URL path to fetch content for (e.g., '/home', '/about')
   * @param pageModel - The model to fetch content from (default is 'page')
   * @returns Promise containing the page content or null if not found
   * @throws Error if the API request fails
   */
  async getPageContent(urlPath: string, pageModel: BuilderPageModel = BuilderPageModel.BasePage): Promise<BuilderPageContent | null> {
    try {
      return await builder
        .get(pageModel, {
          userAttributes: {
            urlPath,
          },
          cacheSeconds: 10,
          staleCacheSeconds: 10,
          prerender: false,
        })
        .toPromise();
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      throw new Error(`Failed to fetch Builder.io content: ${message}`);
    }
  }
}