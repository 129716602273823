import {
	TypeCollectionSkeleton,
	TypeCollectionWithoutUnresolvableLinksResponse,
	TypeConditionSkeleton,
	TypeConditionWithoutUnresolvableLinksResponse,
	TypeDepartmentAndProgramSkeleton,
	TypeDepartmentAndProgramWithoutUnresolvableLinksResponse,
	TypeHealthAndWellnessSkeleton,
	TypeHealthAndWellnessWithoutUnresolvableLinksResponse,
} from "@/types/contentful";
import {
	createClient,
	EntriesQueries,
	Entry,
	EntrySkeletonType,
	type ContentfulClientApi,
} from "contentful";

import "reflect-metadata";

import { injectable } from "inversify";

import { IContentfulRepository } from "./types";

@injectable()
export class ContentfulRepository implements IContentfulRepository {
	private client: ContentfulClientApi<"WITHOUT_UNRESOLVABLE_LINKS">;

	constructor({
		space,
		environment,
		accessToken,
		host,
	}: {
		space: string;
		environment: string;
		accessToken: string;
		host?: string;
	}) {
		this.client = createClient({
			space,
			environment,
			accessToken,
			host,
		}).withoutUnresolvableLinks;
	}

	async fetchHealthAndWellnessByUrl(
		url: string,
	): Promise<TypeHealthAndWellnessWithoutUnresolvableLinksResponse> {
		const results =
			await this.client.getEntries<TypeHealthAndWellnessSkeleton>({
				content_type: "healthAndWellness",
				include: 2,
				limit: 1,
				"fields.url": url,
			});

		return (results.items || [])[0] || null;
	}

	async fetchDepartmentProgramByUrl(
		url: string,
	): Promise<TypeDepartmentAndProgramWithoutUnresolvableLinksResponse> {
		const fieldsQuery = async (
			fields: (
				| "fields.brandedName"
				| "fields.departmentprogramName"
				| "fields.carouselSlides"
				| "fields.contactUsSidebar"
				| "fields.contentAfterQuoteBlock"
				| "fields.description"
				| "fields.entryType"
				| "fields.mainContentArea"
				| "fields.officialName"
				| "fields.practiceName"
				| "fields.promo"
				| "fields.promoBlock"
				| "fields.quoteBlock"
				| "fields.seo"
				| "fields.slug"
				| "fields.subType"
				| "fields.teaserDescription"
				| "fields.textBlock"
				| "fields.translated"
				| "fields.usnwrBadge"
				| "fields.valueProp"
				| "fields.widgets"
				| "fields.relatedPrograms"
				| "fields.referralSpecialty"
				| "fields.relatedPatientStories"
				| "fields.relatedProviders"
				| "fields.relatedTreatments"
				| "fields.relatedConditions"
				| "fields.fullWidthColorBlock"
			)[],
		) => {
			const relatedFieldsQueryResult =
				await this.client.getEntries<TypeDepartmentAndProgramSkeleton>({
					content_type: "departmentAndProgram",
					include: 3,
					limit: 1,
					select: fields,
					"fields.slug": url,
				});

			return (relatedFieldsQueryResult.items || [])[0] || null;
		};
		const record = await fieldsQuery([
			"fields.brandedName",
			"fields.departmentprogramName",
			"fields.carouselSlides",
			"fields.contactUsSidebar",
			"fields.contentAfterQuoteBlock",
			"fields.description",
			"fields.entryType",
			"fields.mainContentArea",
			"fields.officialName",
			"fields.practiceName",
			"fields.promo",
			"fields.promoBlock",
			"fields.quoteBlock",
			"fields.seo",
			"fields.slug",
			"fields.subType",
			"fields.teaserDescription",
			"fields.textBlock",
			"fields.translated",
			"fields.usnwrBadge",
			"fields.valueProp",
			"fields.widgets",
			"fields.fullWidthColorBlock",
		]);
		if (record) {
			// Fetch related fields too big to fetch in a single record

			const [
				relatedConditionsRec,
				relatedProgramsRec,
				relatedPatientStoriesRec,
				relatedFieldRec,
			] = await Promise.all([
				fieldsQuery(["fields.relatedConditions"]),
				fieldsQuery(["fields.relatedPrograms"]),
				fieldsQuery(["fields.relatedPatientStories"]),
				fieldsQuery([
					"fields.referralSpecialty",
					"fields.relatedProviders",
					"fields.relatedTreatments",
				]),
			]);

			return {
				...record,
				fields: {
					...record.fields,
					...relatedConditionsRec.fields,
					...relatedProgramsRec.fields,
					...relatedPatientStoriesRec.fields,
					...relatedFieldRec.fields,
				},
			};
		}

		return record;
	}

	async fetchCollectionByUrl(
		url: string,
	): Promise<TypeCollectionWithoutUnresolvableLinksResponse> {
		const results = await this.client.getEntries<TypeCollectionSkeleton>({
			content_type: "collection",
			include: 1,
			limit: 1,
			"fields.slug": url,
		});

		return (results.items || [])[0] || null;
	}

	async fetchDepartmentProgramsByLinkedEntry(
		linkedEntryId: string,
	): Promise<TypeDepartmentAndProgramWithoutUnresolvableLinksResponse[]> {
		const results =
			await this.client.getEntries<TypeDepartmentAndProgramSkeleton>({
				content_type: "departmentAndProgram",
				include: 1,
				select: [
					"sys.id",
					"fields.departmentprogramName",
					"fields.officialName",
					"fields.brandedName",
					"fields.description",
					"fields.entryType",
					"fields.slug",
					"fields.teaserDescription",
					"fields.subType",
				],
				links_to_entry: linkedEntryId,
			});

		return results.items || null;
	}

	async fetchConditionsByLinkedEntry(
		linkedEntryId: string,
	): Promise<TypeConditionWithoutUnresolvableLinksResponse[]> {
		const results = await this.client.getEntries<TypeConditionSkeleton>({
			content_type: "condition",
			include: 1,
			select: [
				"sys.id",
				"fields.conditionName",
				"fields.entryType",
				"fields.alternateName",
				"fields.url",
			],
			links_to_entry: linkedEntryId,
		});

		return results.items || null;
	}

	async fetchCollectionItemsById<
		EntriesSkeleton extends EntrySkeletonType,
		ContentType extends string,
		EntryResponseType extends Entry<
			EntriesSkeleton,
			"WITHOUT_UNRESOLVABLE_LINKS",
			ContentType
		>,
	>(
		collectionEntryId: string,
		contentType: ContentType,
	): Promise<EntryResponseType[]> {
		const results = await this.client.getEntries<EntriesSkeleton>({
			content_type: contentType,
			include: 1,
			links_to_entry: collectionEntryId,
		});

		return (results.items as EntryResponseType[]) || null;
	}
}
