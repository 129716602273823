export const pick = <Data extends object, Keys extends keyof Data>(
	data: Data,
	keys: Keys[],
): Pick<Data, Keys> => {
	const result = {} as Pick<Data, Keys>;

	for (const key of keys) {
		result[key] = data[key];
	}

	return result;
};

export const omit = <Data extends object, Keys extends keyof Data>(
	data: Data,
	keys: Keys[],
): Omit<Data, Keys> => {
	const result = { ...data };

	for (const key of keys) {
		delete result[key];
	}

	return result as Omit<Data, Keys>;
};
