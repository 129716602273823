import type { ILogger } from "@/lib/logger";
import container from "./container";
import { DI_TYPES } from "./types";
import { ISearchRepository } from "@/repositories/SearchRepository";
import { DotcomService } from "@/services/DotcomService";
import { IHealthWellnessService } from "@/services/HealthWellnessService";
import { IContentfulRepository } from "@/repositories/ContentfulRepository";
import { SpecialtiesServicesService } from "@/services/SpecialtiesServicesService";
import { IDotcomRepository } from "@/repositories/DotcomRepository";
import type { IBuilderService } from "@/services/BuilderService";
import { IBuilderRepository } from '@/repositories/BuilderRepository';

export { DI_TYPES };

export const getLogger = (): ILogger => {
	return container.get<ILogger>(DI_TYPES.Logger);
};

export const getBuilderRepository = (): IBuilderRepository => {
	return container.get<IBuilderRepository>(DI_TYPES.BuilderRepository);
};

export const getContentfulRepository = (): IContentfulRepository => {
	return container.get<IContentfulRepository>(DI_TYPES.ContentfulRepository);
};

export const getDotcomRepository = (): IDotcomRepository => {
	return container.get<IDotcomRepository>(DI_TYPES.DotcomRepository);
};

export const getSearchRepository = (): ISearchRepository => {
	return container.get<ISearchRepository>(DI_TYPES.SearchRepository);
};

export const getBuilderService = (): IBuilderService => {
	return container.get<IBuilderService>(DI_TYPES.BuilderService);
};

export const getHealthWellnessService = (): IHealthWellnessService => {
	return container.get<IHealthWellnessService>(DI_TYPES.HealthWellnessService);
};

export const getSpecialtiesServicesService = (): SpecialtiesServicesService => {
	return container.get<SpecialtiesServicesService>(DI_TYPES.SpecialtiesServicesService);
};

export const getDotcomService = (): DotcomService => {
	return container.get<DotcomService>(DI_TYPES.DotcomService);
};
