export const DI_TYPES = {
	// Utilities
	Logger: Symbol.for("Logger"),
	// Repos
	BuilderRepository: Symbol.for("BuilderRepository"),
	ContentfulRepository: Symbol.for("ContentfulRepository"),
	DotcomRepository: Symbol.for("DotcomRepository"),
	SearchRepository: Symbol.for("SearchRepository"),
	// Services
	BuilderService: Symbol.for("BuilderService"),
	DotcomService: Symbol.for("DotcomService"),
	HealthWellnessService: Symbol.for("HealthWellnessService"),
	SpecialtiesServicesService: Symbol.for("SpecialtiesServicesService"),
} as const;

export type DI_TYPE_KEY = keyof typeof DI_TYPES;
export type DI_TYPE = typeof DI_TYPES[DI_TYPE_KEY];