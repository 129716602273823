import "reflect-metadata";

import { env } from "@/env";
import { logs, SeverityNumber } from "@opentelemetry/api-logs";
import { injectable } from "inversify";

import { AbstractLogger } from "./AbstractLogger";
import { LogLevel } from "./types";

const getSeverityNumberFromLogLevel = (logLevel: LogLevel): SeverityNumber => {
	switch (logLevel) {
		case LogLevel.Debug:
			return SeverityNumber.DEBUG;
		case LogLevel.Info:
			return SeverityNumber.INFO;
		case LogLevel.Warn:
			return SeverityNumber.WARN;
		case LogLevel.Error:
			return SeverityNumber.ERROR;
		case LogLevel.Fatal:
			return SeverityNumber.FATAL;
	}
};

@injectable()
export class ServerLogger extends AbstractLogger {
	log(logLevel: LogLevel, ...args: unknown[]): void {
		const logger = logs.getLogger("Log Provider");
		logger.emit({
			severityNumber: getSeverityNumberFromLogLevel(logLevel),
			body: args.join(" "),
		});
		if (env.NEXT_PUBLIC_SHOW_LOGS_LOCAL) {
			this.logToConsole(logLevel, ...args);
		}
	}
}
