import { env } from "@/env";
import type { ISearchRepository, SearchResult } from "./ISearchRepository";
import 'reflect-metadata';
import { injectable } from "inversify";

@injectable()
export class FusionSearchRepository implements ISearchRepository {
	async searchCategory<DocType extends unknown>(category: string, query: string, searchOptions: {[key: string]: unknown} = {}): Promise<SearchResult<DocType>> {
        const urlParams = new URLSearchParams({
            q: query,
            ...searchOptions,
        });
        const url = `${env.NEXT_PUBLIC_BASE_DOMAIN}${env.NEXT_PUBLIC_FUSION_SEARCH_API}/${category}?${urlParams.toString()}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json() as {response: SearchResult<DocType>};

        return result.response;
    }
}
