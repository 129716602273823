import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
	server: {
		CONTENTFUL_SPACE_ID: z.string().min(1),
		CONTENTFUL_ACCESS_TOKEN: z.string().min(1),
		CONTENTFUL_PREVIEW_TOKEN: z.string().min(1),
		CONTENTFUL_ENVIRONMENT: z
			.string()
			.min(1)
			.describe(
				"The Contenful environment from which the env will pull data.",
			),
		CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN: z.string().min(1),
		NEXT_OTEL_VERBOSE: z.coerce
			.number()
			.catch(0)
			.describe(
				"The environmental flag to drive when nextjs's open-telemetry logging is enabled",
			),
		BUILDER_DEVTOOLS_ENABLED: z.coerce.boolean().optional().default(false),
		BUILDER_CACHE_TIME_SECONDS: z.number().optional().default(3600),
	},
	client: {
		NEXT_PUBLIC_SHOW_LOGS_LOCAL: z
			.string()
			.transform<boolean>((val) => val === "true")
			.optional()
			.default("false")
			.describe(
				"The environmental flag to drive when if the logger is called, if it should also log to the local console",
			),
		NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING: z
			.string()
			.optional(),
		NEXT_PUBLIC_BUILDER_API_KEY: z.string().min(1),
		NEXT_PUBLIC_FUSION_SEARCH_API: z.string(),
		NEXT_PUBLIC_MARKERIO_PROJECTID: z.string().optional(),
		NEXT_PUBLIC_MARKERIO_ENABLED: z
			.string()
			.transform<boolean>((val) => val === "true")
			.optional()
			.default("false"),
		NEXT_PUBLIC_BASE_DOMAIN: z
			.string()
			.default("https://www.childrens.com"),
		NEXT_PUBLIC_BASE_DOMAIN_ES: z
			.string()
			.default("https://esqa.childrens.com"),
		NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string().min(1),
		NEXT_PUBLIC_BRIGHTCOVE_ACCOUNT_ID: z.coerce.string(),
		NEXT_PUBLIC_BRIGHTCOVE_PLAYER_ID: z.coerce.string(),
	},
	runtimeEnv: {
		BUILDER_DEVTOOLS_ENABLED: process.env.BUILDER_DEVTOOLS_ENABLED,
		CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
		CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
		CONTENTFUL_PREVIEW_TOKEN: process.env.CONTENTFUL_PREVIEW_TOKEN,
		CONTENTFUL_ENVIRONMENT: process.env.CONTENTFUL_ENVIRONMENT,
		CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN:
			process.env.CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN,
		BUILDER_CACHE_TIME_SECONDS: process.env.BUILDER_CACHE_TIME_SECONDS,
		NEXT_PUBLIC_SHOW_LOGS_LOCAL: process.env.NEXT_PUBLIC_SHOW_LOGS_LOCAL,
		NEXT_PUBLIC_BUILDER_API_KEY: process.env.NEXT_PUBLIC_BUILDER_API_KEY,
		NEXT_OTEL_VERBOSE: process.env.NEXT_OTEL_VERBOSE,
		NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING:
			process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING,
		NEXT_PUBLIC_FUSION_SEARCH_API:
			process.env.NEXT_PUBLIC_FUSION_SEARCH_API,
		NEXT_PUBLIC_BASE_DOMAIN: process.env.NEXT_PUBLIC_BASE_DOMAIN,
		NEXT_PUBLIC_BASE_DOMAIN_ES: process.env.NEXT_PUBLIC_BASE_DOMAIN_ES,
		NEXT_PUBLIC_MARKERIO_PROJECTID:
			process.env.NEXT_PUBLIC_MARKERIO_PROJECTID,
		NEXT_PUBLIC_MARKERIO_ENABLED: process.env.NEXT_PUBLIC_MARKERIO_ENABLED,
		NEXT_PUBLIC_GOOGLE_MAPS_API_KEY:
			process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
		NEXT_PUBLIC_BRIGHTCOVE_ACCOUNT_ID:
			process.env.NEXT_PUBLIC_BRIGHTCOVE_ACCOUNT_ID,
		NEXT_PUBLIC_BRIGHTCOVE_PLAYER_ID:
			process.env.NEXT_PUBLIC_BRIGHTCOVE_PLAYER_ID,
	},
});
