import {env} from "@/env";
import 'reflect-metadata';
import { injectable } from "inversify";
import type { IDotcomRepository } from "./types";
import { DotcomLocation } from "@/types/dotcom";

@injectable()
export class DotcomRepository implements IDotcomRepository{
	async callMethod(callMethodArgs: {[key: string]: string} = {}): Promise<boolean> {
        const url = `${env.NEXT_PUBLIC_BASE_DOMAIN}/wps/dotcomservice/callmethod`;
        const body = JSON.stringify(callMethodArgs);
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body
        });

        if (!response.ok) {
            throw new Error(`Error with ${url} with body "${body}": ${response.statusText}`);
        }

        return true;
    }

    async fetchLocations(url: string): Promise<DotcomLocation[]> {
        const urlParams = new URLSearchParams({
            url,
        });
        // currently/ this api only works when pointed against/called on production, thus at this time, the domain is hardcoded
        // opposed to referencing the below line
        // const baseDomain = env.NEXT_PUBLIC_BASE_DOMAIN environmental value
        const baseDomain = "https://www.childrens.com";
        const apiUrl = `${baseDomain}/wps/eavservices/eav/getlocations?${urlParams.toString()}`;
    
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorBody = await response.json();
            throw new Error(`Error: ${response.statusText} ${errorBody}`);
        }

        return await response.json();
    }
}
