import "reflect-metadata";

import { ClientLogger, ServerLogger, type ILogger } from "@/lib/logger";
import { DotcomRepository } from "@/repositories/DotcomRepository";
import { FusionSearchRepository, type ISearchRepository } from "@/repositories/SearchRepository";
import { Container } from "inversify";

import { DI_TYPES } from "./types";
import { DotcomService } from "@/services/DotcomService";
import { HealthWellnessService, IHealthWellnessService } from "@/services/HealthWellnessService";
import { type IContentfulRepository } from "@/repositories/ContentfulRepository";
import { ContentfulRepository } from "@/repositories/ContentfulRepository/ContentfulRepository";
import { env } from "@/env";
import { SpecialtiesServicesService } from "@/services/SpecialtiesServicesService";
import { BuilderRepository, type IBuilderRepository } from "@/repositories/BuilderRepository";
import { BuilderService, IBuilderService } from "@/services/BuilderService";

const isServer = typeof window === "undefined";

const container = new Container();

// Setup Logger Singleton
if (isServer) {
	container
		.bind<ILogger>(DI_TYPES.Logger)
		.to(ServerLogger)
		.inTransientScope();
} else {
	container
		.bind<ILogger>(DI_TYPES.Logger)
		.to(ClientLogger)
		.inTransientScope();
}

// Repositories
container
	.bind<IContentfulRepository>(DI_TYPES.ContentfulRepository)
	.toDynamicValue(() => {
		return new ContentfulRepository({
			space: env.CONTENTFUL_SPACE_ID,
			environment: env.CONTENTFUL_ENVIRONMENT,
			accessToken: env.CONTENTFUL_ACCESS_TOKEN
		})
	})
	.inRequestScope();
container
	.bind<ISearchRepository>(DI_TYPES.SearchRepository)
	.to(FusionSearchRepository)
	.inSingletonScope();
container
	.bind<IBuilderRepository>(DI_TYPES.BuilderRepository)
	.to(BuilderRepository)
	.inSingletonScope();
container
	.bind<DotcomRepository>(DI_TYPES.DotcomRepository)
	.to(DotcomRepository)
	.inSingletonScope();

// Services
container
	.bind<IBuilderService>(DI_TYPES.BuilderService)
	.to(BuilderService)
	.inSingletonScope();
container
	.bind<DotcomService>(DI_TYPES.DotcomService)
	.to(DotcomService)
	.inSingletonScope();
container
	.bind<IHealthWellnessService>(DI_TYPES.HealthWellnessService)
	.to(HealthWellnessService)
	.inSingletonScope();
container
	.bind<SpecialtiesServicesService>(DI_TYPES.SpecialtiesServicesService)
	.to(SpecialtiesServicesService)
	.inRequestScope();
export default container;
