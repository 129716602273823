import "reflect-metadata";

import { DI_TYPES } from "@/lib/di/types";
import type { ILogger } from "@/lib/logger";
import { type IContentfulRepository } from "@/repositories/ContentfulRepository";
import type { ISearchRepository } from "@/repositories/SearchRepository";
import {
	TypeCollectionWithoutUnresolvableLinksResponse,
	TypeConditionWithoutUnresolvableLinksResponse,
	TypeDepartmentAndProgramWithoutUnresolvableLinksResponse,
	TypeHealthAndWellnessSkeleton,
	TypeHealthAndWellnessWithoutUnresolvableLinksResponse,
	TypeTagWithoutUnresolvableLinksResponse,
} from "@/types/contentful";
import { inject, injectable } from "inversify";

import type { IHealthWellnessService, RelatedContentItem } from "./types";

@injectable()
export class HealthWellnessService implements IHealthWellnessService {
	constructor(
		@inject(DI_TYPES.Logger) private _logger: ILogger,
		@inject(DI_TYPES.ContentfulRepository)
		private _contentfulRepo: IContentfulRepository,
		@inject(DI_TYPES.SearchRepository)
		private _searchRepo: ISearchRepository,
	) {}

	async fetchRelatedContentForArticle(
		article: TypeHealthAndWellnessWithoutUnresolvableLinksResponse,
	): Promise<RelatedContentItem[]> {
		try {
			const searchQuery = (article.fields.tags || []).map(tag => tag?.fields.tagName).join("+");
			const relatedContent = await this._searchRepo.searchCategory<{
				title_s: string;
				id: string;
				pageSubtype_s: string;
				img_s: string;
				thumb_img_s: string;
			}>("Childrens_HealthWellness", searchQuery, { rows: 12 });

			if (relatedContent?.docs?.length > 0) {
				return relatedContent.docs
					.filter(doc => !doc.id.includes(article.fields.url as string))
					.map((record) => ({
						title: record.title_s,
						type: record.pageSubtype_s,
						url: record.id,
						imageSrc: record.thumb_img_s || record.img_s,
					}));
			}

			return [];
		} catch (err) {
			this._logger.error(
				"Issue search H&W Related content item for an article",
				err,
			);
			return [];
		}
	}

	async fetchArticleEntry(
		healthAndWellnessUrlSlug: string,
	): Promise<TypeHealthAndWellnessWithoutUnresolvableLinksResponse | null> {
		try {
			// Fetch entry
			const healthWellnessEntry =
				await this._contentfulRepo.fetchHealthAndWellnessByUrl(
					`/health-wellness/${healthAndWellnessUrlSlug}`,
				);

			// If valid entry is found, process result into usable format
			if (healthWellnessEntry) {
				// Transform tag urls to handle search query and default sort
				const tags = (healthWellnessEntry.fields.tags || []).filter(Boolean).map(tag  => {
					const {slug, ...fields} = tag?.fields || {};
					const parts = (slug || "").split("?q=");
					let urlToUse = (slug || "");
					if (parts?.length && slug?.includes("/search")) {
						urlToUse = `${parts[0]}?q=${encodeURIComponent(parts[1])}&sort=relevance`;
					}
					return {
						...tag,
						fields: {
							...fields,
							slug: urlToUse,
						}
					} as TypeTagWithoutUnresolvableLinksResponse;
				});

				// For now, replace departmentProgram reference with secondary query result to remove circular reference 
				const departmentProgram = (healthWellnessEntry.fields.departmentProgram as TypeDepartmentAndProgramWithoutUnresolvableLinksResponse[] || []).map(dp => ({
					...dp,
					fields: {
						departmentprogramName: dp.fields.departmentprogramName,
						officialName: dp.fields.officialName,
						brandedName: dp.fields.brandedName,
						description: dp.fields.description,
						entryType: dp.fields.entryType,
						slug: dp.fields.slug,
						teaserDescription: dp.fields.teaserDescription,
						subType: dp.fields.subType,
					}
				}));
				const conditions = (healthWellnessEntry.fields.conditions as TypeConditionWithoutUnresolvableLinksResponse[] || []).map(condition => ({
					...condition,
					fields: {
						conditionName: condition.fields.conditionName,
						entryType: condition.fields.entryType,
						alternateName: condition.fields.alternateName,
						url: condition.fields.url,
						shortTitle: condition.fields.shortTitle,
					},
				}));

				return {
					...healthWellnessEntry,
					fields: { 
						...healthWellnessEntry.fields,
						departmentProgram,
						conditions,
						tags,
					}
				};
			}

			return null;
		} catch (error) {
			this._logger.error(
				`Issue occurred querying & processing HealthWellness record from contentful"`,
				{
					slug: healthAndWellnessUrlSlug,
					error: (error as Error)?.message || error,
				},
			);
			return null;
		}
	}

	async fetchHealthWellnessCollection(
		healthWellnessCollectionSlug: string,
	): Promise<TypeCollectionWithoutUnresolvableLinksResponse | null> {
		try {
			// Fetch entry
			const collectionEntry =
				await this._contentfulRepo.fetchCollectionByUrl(
					`/health-wellness/${healthWellnessCollectionSlug}`,
				);

			// If valid entry is found, process result into usable format
			if (collectionEntry) {
				return collectionEntry;
			}

			return null;
		} catch (error) {
			this._logger.error(
				`Issue occurred querying or processing HealthWellness Collection record from contentful"`,
				{
					slug: healthWellnessCollectionSlug,
					error: (error as Error)?.message || error,
				},
			);
			return null;
		}
	}

	async fetchCollectionItemsById(
		hwCollectionEntryId: string,
	): Promise<TypeHealthAndWellnessWithoutUnresolvableLinksResponse[]> {
		try {
			// Fetch entry
			const collectionItems =
				await this._contentfulRepo.fetchCollectionItemsById<
					TypeHealthAndWellnessSkeleton,
					"healthAndWellness",
					TypeHealthAndWellnessWithoutUnresolvableLinksResponse
				>(hwCollectionEntryId, "healthAndWellness");

			// If valid entry is found, process result into usable format
			if (collectionItems?.length) {
				return collectionItems;
			}

			return [];
		} catch (error) {
			this._logger.error(
				`Issue occurred querying or processing HealthWellness Collection Items from contentful"`,
				{
					collectionEntryId: hwCollectionEntryId,
					error: (error as Error)?.message || error,
				},
			);
			return [];
		}
	}
}
