"use client";

import "reflect-metadata";

import { env } from "@/env";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { injectable } from "inversify";

import { AbstractLogger } from "./AbstractLogger";
import { LogLevel } from "./types";

const getSeverityLevelFromLogLevel = (logLevel: LogLevel): SeverityLevel => {
	switch (logLevel) {
		case LogLevel.Debug:
			return SeverityLevel.Verbose;
		case LogLevel.Info:
			return SeverityLevel.Information;
		case LogLevel.Warn:
			return SeverityLevel.Warning;
		case LogLevel.Error:
			return SeverityLevel.Error;
		case LogLevel.Fatal:
			return SeverityLevel.Critical;
	}
};

@injectable()
export class ClientLogger extends AbstractLogger {
	log(logLevel: LogLevel, ...args: unknown[]): void {
		if (window.appInsights) {
			window.appInsights.trackTrace({
				severityLevel: getSeverityLevelFromLogLevel(logLevel),
				message: args.join(" "),
			});
		}
		// For each of local developer debugging
		// TODO: allow to be overwritten with url-params
		if (env.NEXT_PUBLIC_SHOW_LOGS_LOCAL) {
			this.logToConsole(logLevel, ...args);
		}
	}
}
