export enum BuilderPageModel {
    BasePage = "page",
    HealthWellness = "health-wellness-page",
    ResearchInnovation = 'research-and-innovation-page', 
    DepartmentProgram = 'department-program-page-modal',
    ResearchLibrary = "research-library-page",
    SpecialtiesServices = "specialties-services-page"
};


/**
 * Represents the Builder.io page content response from api
 */
export type BuilderPageContent = {
  data: Record<string, unknown>;
  id: string;
  name: string;
  lastUpdated: number;
};
