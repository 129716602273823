import "reflect-metadata";

import { injectable } from "inversify";

import { ILogger, LogLevel } from "./types";

@injectable()
export abstract class AbstractLogger implements ILogger {
	abstract log(logLevel: LogLevel, ...args: unknown[]): void;

	protected logToConsole(logLevel: LogLevel, ...args: unknown[]): void {
		switch (logLevel) {
			case LogLevel.Debug:
				console.debug("[DEBUG] -", ...args);
				break;
			case LogLevel.Info:
				console.info("[INFO] -", ...args);
				break;
			case LogLevel.Warn:
				console.warn("[WARN] -", ...args);
				break;
			case LogLevel.Error:
				console.error("[ERROR] -", ...args);
				break;
			case LogLevel.Fatal:
				console.error("[FATAL] -", ...args);
				break;
		}
	}

	debug(...args: unknown[]): void {
		this.log(LogLevel.Debug, ...args);
	}

	info(...args: unknown[]): void {
		this.log(LogLevel.Info, ...args);
	}

	warn(...args: unknown[]): void {
		this.log(LogLevel.Warn, ...args);
	}

	error(...args: unknown[]): void {
		this.log(LogLevel.Error, ...args);
	}

	fatal(...args: unknown[]): void {
		this.log(LogLevel.Fatal, ...args);
	}
}
